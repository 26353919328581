import { createContext, ReactNode, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { gtmPolicyTypeAtom } from "../../atoms";
import { CONTEXT_POLICY_NUMBER_SESSION_KEY } from "../../constants";
import { getGtmPolicyTypeFromNumber } from "../../utils/policyUtils";

export const PolicyNumberContext = createContext<string | null>(null);

/**
 * TODO - Provider should navigate to 500 page if policy number is not found.
 * - Currently this is handled in usePreform hook, but desired end state is to have this handled here.
 * - To be able to achieve this, the dependency on usePolicyNumber from hooks like usePage and useBreadcrumbs otherwise the error page will not display.
 * - Potentially could look at replacing those usages with a policy number atom that is set in this provider if the policy number is valid.
 * - The current implementation aligns with other Policy Self Service Spark apps (like CEO/MAP/PCO)
 * - See PR comment https://github.com/racwa/raci-change-my-payment-web/pull/191#discussion_r1710680715 for more info
 * - See PR comment https://github.com/racwa/raci-change-my-payment-web/pull/196#discussion_r1713151124 for more info
 */
export const PolicyNumberProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams();
  const queryParamPolicyNumber = searchParams.get("policyNumber");
  const setGtmPolicyType = useSetRecoilState(gtmPolicyTypeAtom);

  const sessionStoragePolicyNumber = sessionStorage.getItem(CONTEXT_POLICY_NUMBER_SESSION_KEY);

  useEffect(() => {
    if (queryParamPolicyNumber) {
      sessionStorage.setItem(CONTEXT_POLICY_NUMBER_SESSION_KEY, queryParamPolicyNumber ?? "");
      setGtmPolicyType(getGtmPolicyTypeFromNumber(queryParamPolicyNumber ?? ""));
    }
  }, [queryParamPolicyNumber, setGtmPolicyType]);

  return (
    <PolicyNumberContext.Provider value={queryParamPolicyNumber ?? sessionStoragePolicyNumber}>
      {children}
    </PolicyNumberContext.Provider>
  );
};

export default PolicyNumberProvider;
