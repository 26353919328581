import { CxOneVersion } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { howYouPayRoutes, HowYouPayRoutes, preFormRoutes, utilityRoutes } from "../../../views/routes.config";
import useApiClient from "../../useApiClient";
import useHasValidSession from "../useHasValidSession";

export const webChatDisallowPages = [
  ...utilityRoutes,
  ...preFormRoutes,
  ...howYouPayRoutes.filter((r) => r.key === HowYouPayRoutes.Confirmation),
].map((route) => route.path.toLowerCase());

const useGetWebChatInformation = () => {
  const client = useApiClient();
  const location = useLocation();
  const hasValidSession = useHasValidSession();

  const [pointOfContactId, setPointOfContactId] = useState("");
  const [shouldRenderWebChat, setShouldRenderWebChat] = useState(false);
  const [crmId, setCrmId] = useState("");

  const currentLocation = location.pathname.toLowerCase();
  const [getCxOneVersion, setCxOneVersion] = useState(CxOneVersion.Max);
  const shouldGetWebChatInformation = hasValidSession && !webChatDisallowPages?.includes(currentLocation);

  useEffect(() => {
    let isCancelled = false;

    const getWebChatId = async () => {
      try {
        if (!isCancelled) {
          if (shouldGetWebChatInformation) {
            const { result } = await client.getWebChatContactId();
            setPointOfContactId(result.pointOfContactId ?? "");
            setCrmId(result.crmId ?? "");
            setShouldRenderWebChat(true);
            setCxOneVersion(result.useWebChatCxOneAgent ? CxOneVersion.Agent : CxOneVersion.Max);
          } else {
            setShouldRenderWebChat(false);
          }
        }
      } catch {
        setPointOfContactId("");
        setCrmId("");
        setShouldRenderWebChat(false);
      }
    };

    getWebChatId();

    return () => {
      isCancelled = true;
    };
  }, [client, currentLocation, shouldGetWebChatInformation]);
  // CurrentLocation is a required dep otherwise will not check agent availability when user navigates from page to page

  return { pointOfContactId, crmId, shouldRender: shouldRenderWebChat, cxOneVersion: getCxOneVersion };
};

export default useGetWebChatInformation;
